.table-content {
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    align-items: center;
    width: 100%;
    height: 100%;
}

.table-content button {
    margin-top: 15px;
}

.calculs-table {
    width: 50%;
}

.calculs-table tbody {
    width: 100%;
}

.calculs-table tr {
    display: flex;
}

.calculs-table td {
    font-size: 40px;
    margin: 5px;
}

.calculs-table tr td:nth-child(1) {
    flex: 2;
    text-align: right;
}

.calculs-table tr td:nth-child(2) {
    flex: 1;
    display: flex;
    align-items: flex-end;
}

.calculs-table tr td:nth-child(3) {
    flex: 2;
    text-align: left;
}

.timer {
    position: absolute;
    top: 125px;
    right: 100px;
    width: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.timer img {
    width: 40px;
}

.timer {
    font-size: 40px;
}

.notFill {
    display: flex;
    align-items: center;
    margin-left: 3px;
    margin-right: 3px;
    border-radius: 10px;
    animation-name: displaying;
    animation-duration: 0.5s;
}

@keyframes displaying {
    from {
        opacity: 0;
        transform: translateX(30px);
    }
    to {
        opacity: 1;
        transform: translateX(0px);
    }
}