.update-form-section form {
    display: flex;
    flex-direction: column;
}

.standard-field-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
}

.update-form-section h2 {
    margin-top: 0;
    font-weight: normal;
}

.update-form-section div:first-child {
    padding-right: 15px;
    width: 350px;
    min-width: 350px;
}

.update-form-section p {
    display: flex;
    align-items: center;
}

.update-form-section p button {
    margin-right: 10px;
}

.update-form-section li span {
    font-weight: bold;
}

.update-form-section ul {
    list-style-type: none;
    padding-left: 0px;
}

.standard-field-container label {
    font-weight: bold;
}

.standard-field-container input[type="password"],
.standard-field-container input[type="text"] {
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    border: none;
    border-left: 1px solid var(--standard-border-color);
    border-right: 1px solid var(--standard-border-color);
    border-top: 1px solid var(--standard-border-color);
    border-bottom: 1px solid var(--standard-border-color);
    border-radius: 2px;
    margin: 8px 0 8px 0;
    padding: 7px 10px 7px 10px;
    width: 250px;
    height: fit-content;
    font-size: 20px;
    color: #505153;
}

.standard-field-container input[type="password"]:focus,
.standard-field-container input[type="text"]:focus {
    border-top: 1px solid var(--input-border-focus);
    border-right: 1px solid var(--input-border-focus);
    border-bottom: 1px solid var(--input-border-focus);
    box-shadow: 0px 0px 10px 3px var(--input-border-focus);
    outline: none;
    transition: all 0.3s;
}

.submit-button {
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    padding: 10px 15px 10px 15px;
    border: 2px solid var(--header-background-color);
    border-radius: 10px;
    background-color: var(--header-background-color);
    color: var(--header-text-color);
    font-size: 20px;
    font-weight: bold;
}

.submit-button:hover {
    color: var(--header-background-color);
    background-color: var(--header-text-color);
    transition: all 0.5s;
}

.submit-button:active {
    box-shadow: 0px 0px 10px 3px var(--header-background-color);
    color: var(--header-background-color);
    background-color: var(--header-text-color);
    transition: none;
}


/* ----------- Number Input Type ----------*/

.number-field-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
}

.number-field-container label {
    font-weight: bold;
    margin-bottom: 5px;
}

.number-field-container button {
    background-color: var(--header-text-color);
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    width: 40px;
    height: 40px;
    border: 2px solid var(--header-background-color);
    border-radius: 7px;
    font-size: 30px;
    outline: none;
    color: var(--header-background-color);
}

.number-field-container button:active {
    box-shadow: 0px 0px 10px 3px var(--header-background-color);
    color: var(--header-background-color);
    background-color: var(--header-text-color);
    transition: none;
}

.value-container {
    display: inline-block;
    width: 70px;
    font-size: 25px;
    text-align: center;
}

/* ------------------- DELETE BUTTON -------------------- */

.delete-button {
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    padding: 10px 15px 10px 15px;
    border: 2px solid red;
    border-radius: 10px;
    background-color: red;
    color: white;
    font-size: 20px;
    font-weight: bold;
}

.delete-button:hover {
    color: red;
    background-color: white;
    transition: all 0.5s;
}

.delete-button:active {
    box-shadow: 0px 0px 10px 3px red;
    color: red;
    background-color: white;
    transition: none;
}