:root {
    --background-color: rgb(77, 158, 214);
    --form-background: rgba(255, 255, 255);
    --input-background: rgba(110, 110, 110, 0.164);
    --input-border-focus: rgba(77, 157, 214, 0.466);
    --text-color: rgb(80, 81, 83);
    --text-error-message: rgb(255, 0, 0);
    --standard-border-color: #DFDFDF;
    --header-text-color: #FFFFFF;
    --header-background-color: rgb(40, 44, 53);
}

html, body, #root {
    height: 100%;
}

#root {
    display: flex;
    flex-direction: column;
    color: var(--text-color);
    overflow: hidden;
}