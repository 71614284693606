.button-container {
    width: 100%;
}

.button-container button {
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    border: none;
    margin: 15px 0 15px 0;
    padding: 15px 20px 15px 20px;
    width: 100%;
    font-size: 30px;
    font-weight: bold;
    color: var(--form-background);
    background-color: var(--background-color);
    border: 2px solid var(--background-color);
    transition: all 0.5s;
}

.button-container button:hover {
    background-color: var(--form-background);
    color: var(--background-color);
    border: 2px solid var(--background-color);
}

.button-container button:active {
    border-radius: 300px;
}