/*
    gris  : rgba(110, 110, 110, 0.164);
    rouge : rgba(255, 0, 0, 0.164);
    vert  : rgba(119, 178, 85, 0.164);
    bleu  : rgba(0, 123, 255, 0.164);
    jaune : rgba(217, 255, 0, 0.164);
*/

.input-result {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    width: 100%;
    min-width: 110px;
    text-align: center;
    font-size: 40px;
    background-color: rgba(110, 110, 110, 0.164);
    border: none;
    border-radius: 10px;
}

.input-result:focus {
    outline: none;
    background-color: rgba(0, 123, 255, 0.164);
}