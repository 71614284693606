.multiplication-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

.multiplication-content h2 {
    font-size: 40px;
    margin-bottom: 0;
}

.multiplication-content hr {
    margin: 24px 0;
    width: 500px;
    border: none;
    border-top: 1px solid var(--text-color);
    overflow: hidden;
}

.multiplication-content h3 {
    width: 500px;
    margin: 15px 0 0 0;
    text-align: center;
    font-weight: normal;
}

.multiplication-content ul {
    margin: 15px 0 30px 0;
    padding: 0;
    list-style-type: none;
}

.container-prefs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 300px;
    padding: 50px;
}

.container-prefs div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 40px;
}

.container-prefs img {
    width: 40px;
    padding-right: 10px;
}

.multiplication-content button {
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    padding: 10px 15px 10px 15px;
    border: 2px solid var(--header-background-color);
    border-radius: 10px;
    background-color: var(--header-background-color);
    color: var(--header-text-color);
    font-size: 30px;
    font-weight: bold;
}

.multiplication-content button:hover {
    color: var(--header-background-color);
    background-color: var(--header-text-color);
    transition: all 0.5s;
}

.multiplication-content button:active {
    box-shadow: 0px 0px 10px 3px var(--header-background-color);
    color: var(--header-background-color);
    background-color: var(--header-text-color);
    transition: none;
}

.multiplication-content button:disabled {
    background-color: white;
    color: var(--header-background-color);
}

.multiplication-content button:disabled:active {
    box-shadow: none;
    transition: none;
}