.container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--background-color);
    color: var(--text-color);
    height: 100%;
}

.form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--form-background);
    border-radius: 3px;
    width: 500px;
    height: fit-content;
    padding: 15px;
}

.form-container h1 {
    font-size: 50px;
    margin: 0;
    padding: 0;
}

.form-container p {
    font-size: 30px;
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 0;
}

.form-container p:last-child {
    font-size: 15px;
}

.form-container form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
}

.form-container .error-message {
    color: var(--text-error-message);
    text-align: center;
    font-size: 20px;
    margin: 0px;
    padding: 0px;
}