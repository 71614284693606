.container-settings {
    overflow-y: scroll;
    flex: 8;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.side-menu {
    flex: 1;
    min-width: 200px;
    border-right: solid 1px var(--standard-border-color);
}

.side-menu ul {
    list-style: outside none none;
    padding: 0;
    margin: 0;
}

.side-menu li {
    padding: 10px;
    padding-left: 13px;
    color: var(--text-color);
}

.side-menu li:hover {
    background-color: var(--standard-border-color);
    color: white;
}

.active-side-menu-field {
    color: black;
    font-weight: bold;
    border-left: 3px solid var(--header-background-color);
    background-color: rgba(226, 226, 226, 0.678);
}

.side-menu a {
    text-decoration: none;
}

.container-settings .content {
    flex: 6;
}

.content {
    overflow: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content section {
    display: flex;
    align-items: baseline;
    width: 70%;
}

.content hr {
    margin: 24px 0;
    width: 70%;
    border: none;
    border-top: 1px solid var(--standard-border-color);
    overflow: hidden;
}

.content hr:nth-child(1) {
    margin: 0;
}

/* -------------- POPUP CONTAINER -------------- */

/* <div className="popup-container-background">
    <div className="popup-container">
        <h2>Your account has been successfully removed</h2>
        <p>We hope to see you again soon!</p>

        <p>Click to be redirect on the <a href="/sign-in">Login page</a></p>
    </div>
</div> */

.popup-container-background {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(161, 161, 161, 0.5);
}

.popup-container {
    background-color: white;
    width: 500px;
    padding: 40px;
}

.popup-container h2 {
    margin-top: 0px;
    text-align: center;
}

.popup-container p {
    text-align: center;
}

.popup-description {
    font-size: 30px;
}

.popup-text-redirection {
    font-size: 15px;
}