.breadcrumb h4 span {
    font-weight: normal;
    font-size: 12px;
}

.breadcrumb h4 span:last-child {
    font-weight: bold;
}

.breadcrumb h4 {
    margin-bottom: 0;
}