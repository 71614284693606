.field-container {
    display: flex;
}

.field-container img {
    border-top: 2px solid var(--form-background);
    border-left: 2px solid var(--form-background);
    border-bottom: 2px solid var(--form-background);
    width: 40px;
    margin: 15px 0 15px 0;
    padding: 15px 0 15px 20px;
    background-color: var(--input-background);
}

.field-container input[type="text"],
.field-container input[type="password"] {
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    border: none;
    border-top: 2px solid var(--form-background);
    border-right: 2px solid var(--form-background);
    border-bottom: 2px solid var(--form-background);
    border-radius: 1px;
    background-color: var(--input-background);
    margin: 15px 0 15px 0;
    padding: 15px 20px 15px 20px;
    width: 100%;
    height: fit-content;
    font-size: 30px;
    color: #505153;
}

.field-container input[type="text"]:focus,
.field-container input[type="password"]:focus {
    border-top: 2px solid var(--input-border-focus);
    border-right: 2px solid var(--input-border-focus);
    border-bottom: 2px solid var(--input-border-focus);
    outline: none;
    transition: all 0.5s;
}