.container-practice {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    background-color: #F7F8F8;
}

.container-practice h2 {
    font-size: 40px;
    margin-bottom: 0;
}

.container-practice h3 {
    margin: 15px 0 60px 0;
    font-weight: normal;
}

.container-practice hr {
    margin: 24px 0;
    width: 50%;
    border: none;
    border-top: 1px solid var(--text-color);
    overflow: hidden;
}

.container-category-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.category-section,
.unavailable-content {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    width: 25%;
    padding: 25px;
    margin-right: 5%;
    color: var(--text-color);
    background-color: white;
    border-radius: 5px;
    border: 2px solid rgb(77, 158, 214);
}

.category-section:hover {
    border: 2px solid white;
    box-shadow: 0px 0px 10px 3px rgb(77, 158, 214);
    transition: all 0.2s;
}

.category-section:nth-child(1),
.unavailable-content:nth-child(1) {
    margin-left: 5%;
}

.category-section:last-child,
.unavailable-content:last-child {
    margin-right: 5%;
}

.category-section h4 {
    color: rgb(77, 158, 214);
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 30px;
}

.category-section h5 {
    color: rgb(77, 158, 214);
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 15px;
}

.preferences-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.preferences-container table {
    margin-left: 10px;
}

.preferences-container tr td:first-child {
    text-align: right;
}

.preferences-container tr td:last-child {
    text-align: left;
}

/* ---------------- UNAVAILABLE CONTENT --------------- */

.unavailable-content {
    border: 2px solid var(--text-color);
    background-color: rgba(233, 233, 233, 0.164);
    pointer-events: none;
    cursor: not-allowed;
}

.unavailable-content:hover {
    border: 2px solid var(--text-color);
    box-shadow: none;
    transition: all 0.2s;
}

.unavailable-content h4,
.unavailable-content h5 {
    color: var(--text-color);
}