.pages-header {
    display: flex;
    justify-content: space-between;
    background-color: var(--header-background-color);
    font-weight: bold;
}

.pages-header h1 {
    margin: 15px;
}

.pages-header nav {
    margin: 15px;
}

.pages-header ul {
    display: flex;
    align-items: center;
    list-style: outside none none;
    margin: 0;
    padding: 0;
    height: 100%;
}

.pages-header li {
    display: flex;
    justify-content: center;
    margin: 0px 15px 0px 15px;
}

.pages-header a {
    text-decoration: none;
    color: var(--header-text-color);
}